import * as client_hooks from '../../../src/hooks.client.ts';

export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23')
];

export const server_loads = [0,4,2,6];

export const dictionary = {
		"/(website)": [~11,[4],[5]],
		"/(website)/appointments/[slug]": [~12,[4],[5]],
		"/(app)/app/(auth)/session/error": [7,[2,3]],
		"/(app)/app/(auth)/session/login": [8,[2,3]],
		"/(website)/blog": [~13,[4],[5]],
		"/(website)/blog/articles/[slug]": [~14,[4],[5]],
		"/(website)/company/about-us": [15,[4],[5]],
		"/(website)/company/contact": [16,[4],[5]],
		"/(website)/company/subscription-terms": [17,[4],[5]],
		"/(backend)/healthz/liveness": [9],
		"/(backend)/healthz/readiness": [10],
		"/(website)/legal/[slug]": [~18,[4],[5]],
		"/(website)/pricing": [19,[4],[5]],
		"/(website)/solution/_sustainability-compliance": [21,[4],[5]],
		"/(website)/solution/_sustainable-procurement": [22,[4],[5]],
		"/(website)/solution/[slug]": [~20,[4],[5]],
		"/(website)/supply-chain/[slug]": [~23,[6]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
};

export { default as root } from '../root.svelte';